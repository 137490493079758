/*==== 1. GLOBAL STYLES ====*/
/* === 1.1 Body === */
html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    overflow-x: hidden;
}

body {
    background-color: #ffffff;
    color: #282828;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 35px;
    margin: 0;
    position: relative;
    overflow-x: hidden;
    padding: 0 !important;
}

.about-content {
    text-align: center;
}

.google-maps-container {
    width: 20vw;
    height: 20vw;
    border-radius: 20px;
    overflow: hidden;
}

.footer-container {
    display: flex;
    justify-content: center;
}

.footer-area a {
    text-decoration: underline !important;
}

@media (max-width: 768px) {
    .footer-area {
        padding-top: 180px !important;
    }

    .google-maps-container {
        width: 100%;
        height: calc(100vw - 72px);
    }

    .partner-area{
        padding-bottom: 20px !important;
    }
}

@media only screen and (max-width: 414px) {
    .slide-thumb:before {
        bottom: 0px !important;
    }
}

.footer-icon{
    margin-right: 5px;
}

/* ==== 2. Typography ==== */
/* === 1.2 Hyperlink === */
a {
    color: #303030;
    /*transition*/
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
}

a, a > * {
    cursor: pointer;
    outline: medium none;
    text-decoration: none;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
    color: inherit;
}

:focus {
    outline: none;
}

/*input and button type focus outline disable*/
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
select:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit;
}

/* === 2.1 Headings === */
h1, h2, h3, h4, h5, h6 {
    color: #282828;
    margin: 0 0 15px;
    padding: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    font-family: 'Jost', sans-serif;
}

h1 {
    font-size: 36px;
    line-height: 42px;
}

h2 {
    font-size: 30px;
    line-height: 35px;
}

h3 {
    font-size: 28px;
    line-height: 32px;
}

h4 {
    font-size: 24px;
    line-height: 38px;
}

h5 {
    font-size: 20px;
    line-height: 30px;
}

h6 {
    font-size: 18px;
}

/* === PARAGRAPH === */
p {
    margin: 0 0 10px;
}

b, strong {
    font-weight: 600;
}

.pl-15 {
    padding-left: 15px;
}

/* === Basic Margins Paddings ===*/
/*=== Padding Top ===*/
.pt-40 {
    padding-top: 40px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-66 {
    padding-top: 66px;
}

.pt-65 {
    padding-top: 65px;
}

.pt-70 {
    padding-top: 70px;
}

.pt-80 {
    padding-top: 80px;
}

.pt-85 {
    padding-top: 85px;
}

.pt-90 {
    padding-top: 90px;
}

.pt-95 {
    padding-top: 95px;
}

.pt-100 {
    padding-top: 100px;
}

.pt-105 {
    padding-top: 105px;
}

.pt-110 {
    padding-top: 110px;
}

.pt-115 {
    padding-top: 115px;
}

.pt-120 {
    padding-top: 120px;
}

.pt-170 {
    padding-top: 170px;
}

.pt-200 {
    padding-top: 200px;
}

.pt-315 {
    padding-top: 315px;
}

/*Padding Bottom*/
.pb-20 {
    padding-bottom: 20px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pb-65 {
    padding-bottom: 65px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pb-85 {
    padding-bottom: 85px;
}

.pb-90 {
    padding-bottom: 90px;
}

.pb-95 {
    padding-bottom: 95px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pb-105 {
    padding-bottom: 105px;
}

.pb-110 {
    padding-bottom: 110px;
}

.pb-115 {
    padding-bottom: 115px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pb-125 {
    padding-bottom: 125px;
}

.pb-150 {
    padding-bottom: 150px;
}

.pb-180 {
    padding-bottom: 180px;
}

.pb-200 {
    padding-bottom: 200px;
}

.pb-220 {
    padding-bottom: 220px;
}

.pb-315 {
    padding-bottom: 315px;
}


/*Margin Bottom*/
.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mb-80 {
    margin-bottom: 80px;
}

/*Margin top*/
.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-105 {
    margin-top: 105px;
}

.mt-115 {
    margin-top: 115px;
}

.mb-120 {
    margin-bottom: 120px;
}

.ml-70 {
    margin-left: 70px;
}

/* === 1.5 OTHER FIXES === */
*, *:before, *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.aligncenter {
    display: block;
    margin: 0 auto 15px
}

img {
    max-width: 100%;
    height: auto;
}

.main-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.video {
    max-width: 900px;
}

.about-bg {
    display: flex;
    justify-content: center;
    align-items: start;
}

.form-select:focus {
    border-color: #ff5300;
    outline: 0;
    box-shadow: 0 0 0 .15rem rgba(255, 83, 0, 0.25);
}

/* no-gutters Class Rules */
.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.row.no-gutters [class^="col-"], .row.no-gutters [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
}

/*Preloader*/
.preloader-wrap {
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #3C368C;
    background: -webkit-linear-gradient(to left, #00c6ff, #0072ff);
    background: linear-gradient(to left, #00c6ff, #0072ff);
    position: absolute;
}

.cube-folding {
    width: 50px;
    height: 50px;
    display: inline-block;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    font-size: 0;
}

.cube-folding span {
    position: relative;
    width: 25px;
    height: 25px;
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    display: inline-block;
}

.cube-folding span::before {
    content: '';
    background-color: #3C368C;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 25px;
    height: 25px;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -moz-animation: folding 2.5s infinite linear both;
    -webkit-animation: folding 2.5s infinite linear both;
    animation: folding 2.5s infinite linear both;
}

.cube-folding .leaf2 {
    -moz-transform: rotateZ(90deg) scale(1.1);
    -ms-transform: rotateZ(90deg) scale(1.1);
    -webkit-transform: rotateZ(90deg) scale(1.1);
    transform: rotateZ(90deg) scale(1.1);
}

.cube-folding .leaf2::before {
    -moz-animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    background-color: #FEAC01;
}

.cube-folding .leaf3 {
    -moz-transform: rotateZ(270deg) scale(1.1);
    -ms-transform: rotateZ(270deg) scale(1.1);
    -webkit-transform: rotateZ(270deg) scale(1.1);
    transform: rotateZ(270deg) scale(1.1);
}

.cube-folding .leaf3::before {
    -moz-animation-delay: 0.9s;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
    background-color: #FEAC01;
}

.cube-folding .leaf4 {
    -moz-transform: rotateZ(180deg) scale(1.1);
    -ms-transform: rotateZ(180deg) scale(1.1);
    -webkit-transform: rotateZ(180deg) scale(1.1);
    transform: rotateZ(180deg) scale(1.1);
}

.cube-folding .leaf4::before {
    -moz-animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
    background-color: #3C368C;
}

@-moz-keyframes folding {
    0%, 10% {
        -moz-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%, 75% {
        -moz-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%, 100% {
        -moz-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

@-webkit-keyframes folding {
    0%, 10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%, 75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%, 100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

@keyframes folding {
    0%, 10% {
        -moz-transform: perspective(140px) rotateX(-180deg);
        -ms-transform: perspective(140px) rotateX(-180deg);
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%, 75% {
        -moz-transform: perspective(140px) rotateX(0deg);
        -ms-transform: perspective(140px) rotateX(0deg);
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%, 100% {
        -moz-transform: perspective(140px) rotateY(180deg);
        -ms-transform: perspective(140px) rotateY(180deg);
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

.cube-wrapper {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
    text-align: center;
}

.cube-wrapper:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    margin: auto;
    width: 90px;
    height: 6px;
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-filter: blur(2px);
    filter: blur(2px);
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    z-index: 1;
    -moz-animation: shadow 0.5s ease infinite alternate;
    -webkit-animation: shadow 0.5s ease infinite alternate;
    animation: shadow 0.5s ease infinite alternate;
}

.cube-wrapper .loading {
    font-size: 14px;
    letter-spacing: 1px;
    display: block;
    color: #ffffff;
    position: relative;
    top: 25px;
    z-index: 2;
    -moz-animation: text 0.5s ease infinite alternate;
    -webkit-animation: text 0.5s ease infinite alternate;
    animation: text 0.5s ease infinite alternate;
}

@-moz-keyframes text {
    100% {
        top: 35px;
    }
}

@-webkit-keyframes text {
    100% {
        top: 35px;
    }
}

@keyframes text {
    100% {
        top: 35px;
    }
}

@-moz-keyframes shadow {
    100% {
        bottom: -18px;
        width: 100px;
    }
}

@-webkit-keyframes shadow {
    100% {
        bottom: -18px;
        width: 100px;
    }
}

@keyframes shadow {
    100% {
        bottom: -18px;
        width: 100px;
    }
}

.info-banner {
    background-color: #f4f4f9;
    padding: 20px 0;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid #0071bc;
}

.banner-icon {
    max-width: 100px;
    height: auto;
}

.banner-title {
    font-size: 2rem;
    color: #0071bc;
    font-weight: bold;
}

.banner-subtitle {
    font-size: 1.2rem;
    color: #333;
    margin: 5px 0;
}

.banner-note {
    font-size: 1rem;
    color: #0071bc;
    font-style: italic;
}

